import { useDispatch } from 'react-redux';
import classnames from 'classnames';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';
import { CheckCircleOutlinedSize16, InfoCircleOutlinedSize16, ThumbUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import HSpacing from 'bloko/blocks/hSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import VacancyResponseLink from 'src/components/VacancyResponseLink';
import VacancyResponsePopup from 'src/components/VacancyResponsePopup';
import CountriesProfileVisibilityAgreementPopup from 'src/components/VacancyResponsePopup/CountriesProfileVisibilityAgreementPopup';
import RelocationWarningPopup from 'src/components/VacancyResponsePopup/RelocationWarningPopup';
import ChatikButton from 'src/components/VacancySearchItem/ChatikButton';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import { useWorkflowState } from 'src/components/VacancySearchItem/hooks/useWorkflowState';
import Source from 'src/components/VacancySearchItem/types/Source';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { incrementApplicantInvitationsCount } from 'src/models/userStats';

import styles from './styles.less';

const TrlKeys = {
    response: 'search.result.item.response',
    responded: 'vacancySearchResults.applicantflags.responded',
    youWereInvited: 'vacancySearchResults.applicantflags.youWereInvited',
    discard: 'vacancySearchResults.applicantflags.rejected',
    chat: 'vacancySearchResults.chat',
    toChat: 'vacancySearchResults.toChat',
};

interface ResponseButtonProps {
    vacancyId: number;
    employerId: number;
    vrImmediateRedirectUrl?: string;
    vacancySource: Source;
    clickUrl?: string;
    vacancyOfTheDayClickUrl?: string;
    isAdv?: boolean;
    isStretched?: boolean;
    isNoPopups?: boolean;
    isResponseButtonVisible?: boolean;
}

enum WorkflowStatus {
    RESPONDED = 'responded',
    INVITED = 'invited',
    DISCARD = 'discard',
}

const ResponseButton: TranslatedComponent<ResponseButtonProps> = ({
    vacancyId,
    employerId,
    vrImmediateRedirectUrl,
    trls,
    vacancySource,
    clickUrl,
    vacancyOfTheDayClickUrl,
    isAdv,
    isResponseButtonVisible,
    isNoPopups,
    isStretched,
}) => {
    const { isXS } = useBreakpoint();
    const responseStatus = useSelector((state) => state.applicantVacancyResponseStatuses[vacancyId]);
    const dispatch = useDispatch();
    const { wideCardContainerClassName } = useCardContainerClassNames();
    const { isResponded, isInvited, isDiscarded, showWorkflowStatus } = useWorkflowState(vacancyId);

    if (!isResponseButtonVisible) {
        return null;
    }

    const respondedState = (text: string, status: WorkflowStatus) => {
        return (
            <div className={styles.workflowStatusContainer} data-qa={`vacancy-serp__vacancy_${status}`}>
                {status === 'responded' && (
                    <BlokoIconReplaceContainer>
                        <ThumbUpOutlinedSize16 initialColor="secondary" />
                    </BlokoIconReplaceContainer>
                )}
                {status === 'invited' && (
                    <BlokoIconReplaceContainer>
                        <CheckCircleOutlinedSize16 initialColor="positive" />
                    </BlokoIconReplaceContainer>
                )}
                {status === 'discard' && (
                    <BlokoIconReplaceContainer>
                        <InfoCircleOutlinedSize16 initialColor="warning" />
                    </BlokoIconReplaceContainer>
                )}
                <HSpacing base={2} />
                <span
                    className={classnames(styles.workflowStatusText, {
                        [styles.workflowStatusTextResponded]: isResponded,
                        [styles.workflowStatusTextInvited]: isInvited,
                        [styles.workflowStatusTextDiscarded]: isDiscarded,
                    })}
                >
                    {text}
                </span>
            </div>
        );
    };

    let workflowState;

    if (isResponded) {
        workflowState = respondedState(trls[TrlKeys.responded], WorkflowStatus.RESPONDED);
    } else if (isInvited) {
        workflowState = respondedState(trls[TrlKeys.youWereInvited], WorkflowStatus.INVITED);
    } else if (isDiscarded) {
        workflowState = respondedState(trls[TrlKeys.discard], WorkflowStatus.DISCARD);
    }

    let toUrl = `/applicant/vacancy_response?vacancyId=${vacancyId}${employerId ? `&employerId=${employerId}` : ''}`;
    if (vrImmediateRedirectUrl) {
        toUrl = updateUrl(vrImmediateRedirectUrl, { hhtmFromLabel: 'vacancy_immediate_redirect' });
    }

    const adVacancyClickUrl = isAdv && clickUrl ? updateUrl(clickUrl, { clickType: 'response_button' }) : undefined;

    if (showWorkflowStatus) {
        return (
            <div className={classnames(styles.workflowStatusRedesign)}>
                {workflowState}
                <HSpacing base={3} />

                <div className={wideCardContainerClassName}>
                    <ChatikButton vacancyId={vacancyId} vacancySource={vacancySource}>
                        {trls[TrlKeys.chat]}
                    </ChatikButton>
                </div>
            </div>
        );
    }

    return (
        <>
            {!showWorkflowStatus && (
                <VacancyResponseLink
                    vacancyId={vacancyId}
                    employerId={employerId}
                    enableRelocationWarning
                    vrImmediateRedirectUrl={vrImmediateRedirectUrl}
                    vacancySource={vacancySource}
                    adVacancyClickUrl={adVacancyClickUrl}
                    vacancyOfTheDayClickUrl={vacancyOfTheDayClickUrl}
                    place="vacancy_search_item"
                    onResponseComplete={() => {
                        dispatch(incrementApplicantInvitationsCount(1));
                    }}
                    render={(onClick) => {
                        return (
                            <Button
                                Element={SPALink}
                                to={toUrl}
                                target={vrImmediateRedirectUrl ? '_blank' : undefined}
                                onClick={onClick}
                                loading={responseStatus?.isFetching && !responseStatus?.isFetchingWithQuestionResponse}
                                disabled={Boolean(responseStatus?.isFetching)}
                                data-qa="vacancy-serp__vacancy_response"
                                stretched={isStretched}
                                style="accent"
                                mode="primary"
                            >
                                {trls[TrlKeys.response]}
                            </Button>
                        );
                    }}
                />
            )}
            {!isNoPopups && (
                <>
                    <RelocationWarningPopup vacancyId={vacancyId} />
                    <CountriesProfileVisibilityAgreementPopup vacancyId={vacancyId} />
                    <VacancyResponsePopup vacancyId={vacancyId} vacancySource={vacancySource} isXs={isXS} />
                </>
            )}
        </>
    );
};

export default translation(ResponseButton);
