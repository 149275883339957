import { FC, useRef } from 'react';

import { employerAchievementCardTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_tooltip_element_shown';
import { TooltipHover } from '@hh.ru/magritte-ui';
import {
    EmployersRatingOutlinedCircledSize16,
    EmployersRatingOutlinedSize16,
    EmployersRatingOutlinedSize24,
} from '@hh.ru/magritte-ui/product';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';

import BadgeContainer from 'src/components/Employer/BadgeContainer';
import { useSelector } from 'src/hooks/useSelector';
import { Badge } from 'src/models/vacancyView.types';

const IconSizeMap = {
    small: EmployersRatingOutlinedSize16,
    medium: EmployersRatingOutlinedSize24,
} as const;

export type RatingIconSize = keyof typeof IconSizeMap;

interface Props {
    badge: Badge;
    size?: RatingIconSize;
    dataQaPrefix: string;
    isRedesignedSerp?: boolean;
    isMagritte?: boolean;
    onClick?: VoidFunction;
}

const EmployerHHRatingIcon: FC<Props> = ({
    badge,
    size = 'small',
    dataQaPrefix,
    isRedesignedSerp,
    isMagritte,
    onClick,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const employerId = useSelector((state) => state.employerInfo.id);

    if (isMagritte) {
        return (
            <SPALink
                to={badge.url}
                target="_blank"
                rel="nofollow noindex"
                data-qa={`${dataQaPrefix}${badge.type}` || ''}
            >
                <span ref={activatorRef}>
                    <EmployersRatingOutlinedCircledSize16 initialColor="warning-secondary" />
                </span>
                <TooltipHover placement="top-center" activatorRef={activatorRef}>
                    {badge.description}
                </TooltipHover>
            </SPALink>
        );
    }

    const IconComponent = IconSizeMap[size];

    return (
        <SPALink
            to={badge.url}
            target="_blank"
            rel="nofollow noindex"
            onClick={onClick}
            data-qa={`${dataQaPrefix}${badge.type}` || ''}
        >
            <HoverTip
                render={() => badge.description}
                onHover={(show) =>
                    show &&
                    employerAchievementCardTooltipElementShownRaw({
                        employerId: String(employerId),
                        achievementType: 'rating_hh',
                    })
                }
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.SSR ? document.body : null}
                activatorRef={activatorRef}
            >
                {isRedesignedSerp ? (
                    <span className={'vacancy-serp-bage-hr-rating'} ref={activatorRef} />
                ) : (
                    <span ref={activatorRef}>
                        <BadgeContainer size={size} icon={<IconComponent scale={size} initialColor="accent" />} />
                    </span>
                )}
            </HoverTip>
        </SPALink>
    );
};

export default EmployerHHRatingIcon;
