import { type ReactElement, useState, useRef } from 'react';

import {
    CheckableCard,
    Link,
    VSpacing,
    VSpacingContainer,
    Text as MagritteText,
    useCollapsible,
} from '@hh.ru/magritte-ui';
import { ChevronUpOutlinedSize16, ChevronDownOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import type { ResponseStatusResume, ShortVacancy } from 'src/models/applicantVacancyResponseStatuses';

import VacancyResponseFormResume from 'src/components/VacancyResponseForm/Resume';
import { RESUME_SELECTOR_LIMIT_TO_COLLAPSE } from 'src/components/VacancyResponseForm/constants';

const TrlKeys = {
    resumesTitle: 'vacancy.response.popup.resumes',
    questionResumesTitle: 'vacancy.questionResponse.popup.resumes',
    hiddenResumes: 'vacancy.response.popup.hidden.resumes',
    showMore: 'vacancy.response.popup.resumes.action.showMore',
    showLess: 'vacancy.response.popup.resumes.action.showLess',
};

interface ResumeSelectorProps {
    responseStatus: ShortVacancy;
    resumes: ResponseStatusResume[];
    selectedResume: ResponseStatusResume;
    isQuestionResponse?: boolean;
    setSelectedResume: (value: ResponseStatusResume) => void;
    onResumeSelected?: () => void;
    isBottomSheet?: boolean;
}

const ResumeSelector: TranslatedComponent<ResumeSelectorProps> = ({
    trls,
    responseStatus,
    resumes,
    selectedResume,
    isQuestionResponse,
    setSelectedResume,
    onResumeSelected,
    isBottomSheet,
}) => {
    const vacancy = responseStatus.shortVacancy;
    const hasHiddenResumes = responseStatus.hiddenResumeIds.length > 0;
    const isSingleResume = resumes.length === 1;

    const hasCollapsibleItems = !isBottomSheet && resumes.length > RESUME_SELECTOR_LIMIT_TO_COLLAPSE;
    const collapsibleElemRef = useRef<HTMLDivElement>(null);

    // We store resumes as list with preselected resume as first item to always show it on top of the list.
    const sortedResumes = useRef(
        resumes.reduce((result, resume) => {
            if (resume.id === selectedResume.id) {
                result.unshift(resume);
            } else {
                result.push(resume);
            }

            return result;
        }, [] as ResponseStatusResume[])
    );

    // As we store selected resume on top of the list we can display list collapsed by default,
    // so selected value will never be collapsed.
    const [isCollapsed, setIsCollapsed] = useState(true);
    const { collapsibleClasses } = useCollapsible(collapsibleElemRef, !isCollapsed);

    const itemRenderer = (resume: ResponseStatusResume): ReactElement<typeof VacancyResponseFormResume> => {
        const selectResume = () => {
            setSelectedResume(resume);
            onResumeSelected?.();

            // Хак, чтобы побороть "Магритовское расстройство" (c) @e.savin
            window.requestAnimationFrame(() => {
                if (collapsibleElemRef.current) {
                    collapsibleElemRef.current.style.maxHeight = 'max-content';
                }
            });
        };

        const isChecked = resume.id === selectedResume.id;

        return (
            <CheckableCard
                key={resume.hash}
                type="radio"
                checked={isChecked}
                padding={16}
                borderRadius={16}
                onChange={selectResume}
                onClick={selectResume}
            >
                <VacancyResponseFormResume
                    resume={resume}
                    single={isSingleResume}
                    hidden={responseStatus.hiddenResumeIds.includes(String(resume.id))}
                    selected={isChecked}
                    visibility={responseStatus.resumeVisibility?.[resume.id]}
                    vacancy={vacancy}
                    isQuestion={isQuestionResponse}
                    isBottomSheet={isBottomSheet}
                />
            </CheckableCard>
        );
    };

    return (
        <div>
            <VSpacingContainer default={12}>
                {hasHiddenResumes && (
                    <MagritteText typography="label-3-regular">{trls[TrlKeys.hiddenResumes]}</MagritteText>
                )}
                {sortedResumes.current
                    .slice(0, hasCollapsibleItems ? RESUME_SELECTOR_LIMIT_TO_COLLAPSE : Infinity)
                    .map(itemRenderer)}
            </VSpacingContainer>

            {hasCollapsibleItems && (
                <VSpacingContainer default={12}>
                    <div className={collapsibleClasses} ref={collapsibleElemRef}>
                        <VSpacing default={12} />
                        <VSpacingContainer default={12}>
                            {sortedResumes.current.slice(RESUME_SELECTOR_LIMIT_TO_COLLAPSE).map(itemRenderer)}
                        </VSpacingContainer>
                    </div>
                    <Link
                        Element="button"
                        iconRight={isCollapsed ? <ChevronDownOutlinedSize16 /> : <ChevronUpOutlinedSize16 />}
                        disabled={
                            !isCollapsed &&
                            sortedResumes.current.findIndex((resume) => resume.id === selectedResume.id) >=
                                RESUME_SELECTOR_LIMIT_TO_COLLAPSE
                        }
                        onClick={(event) => {
                            event.preventDefault();
                            setIsCollapsed(!isCollapsed);
                        }}
                    >
                        {isCollapsed
                            ? formatToReactComponent(trls[TrlKeys.showMore], {
                                  '{0}': resumes.length - RESUME_SELECTOR_LIMIT_TO_COLLAPSE,
                              })
                            : trls[TrlKeys.showLess]}
                    </Link>
                </VSpacingContainer>
            )}
        </div>
    );
};

export default translation(ResumeSelector);
