import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CdnImg from 'src/components/CdnImg';
import translation from 'src/components/translation';
import useGetCompanyUrl from 'src/hooks/companies/useGetCompanyUrl';
import { VacancyCompany } from 'src/types/search/vacancy/card';

enum LogoVariants {
    CustomVacancyLogo = 'customVacancyLogo',
    Medium = 'medium',
}

const isLogoVariant = (variant: string): variant is LogoVariants =>
    Object.values<string>(LogoVariants).includes(variant);

interface LogoTypes {
    [LogoVariants.CustomVacancyLogo]?: string;
    [LogoVariants.Medium]?: string;
}

interface LogoProps {
    company: VacancyCompany;
    shouldShow: boolean;
    isEmployerLinkDisabled?: boolean;
}

const TrlKeys = {
    vacancies: 'navi.item.vacancy',
};

const Logo: TranslatedComponent<LogoProps> = ({ trls, company, shouldShow, isEmployerLinkDisabled }) => {
    const companyUrl = useGetCompanyUrl(company);
    const showCompanyLogo = company.logos && (company.logos['@showInSearch'] || shouldShow);
    if (!showCompanyLogo) {
        return null;
    }

    const logoVariants = company.logos?.logo?.reduce<LogoTypes>((logos, logo) => {
        const type = logo['@type'];
        if (isLogoVariant(type)) {
            logos[type] = logo['@url'];
        }
        return logos;
    }, {});

    const visibleLogoUrl = logoVariants?.customVacancyLogo || logoVariants?.medium;
    if (!visibleLogoUrl) {
        return null;
    }

    const cdnImage = (
        <CdnImg
            loading="lazy"
            path={visibleLogoUrl}
            alt={company.visibleName}
            className={'vacancy-serp-item-logo'}
            data-qa="vacancy-serp__vacancy-employer-logo-image"
        />
    );

    return (
        <div className={'vacancy-serp-item-body__logo_magritte'}>
            {isEmployerLinkDisabled ? (
                <span data-qa="vacancy-serp__vacancy-employer-logo">{cdnImage}</span>
            ) : (
                <SPALink
                    to={companyUrl}
                    data-qa="vacancy-serp__vacancy-employer-logo"
                    aria-label={`${trls[TrlKeys.vacancies]} ${company.visibleName}`}
                >
                    {cdnImage}
                </SPALink>
            )}
        </div>
    );
};

export default translation(Logo);
