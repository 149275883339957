import { useEffect } from 'react';

import { VSpacing, HSpacing, Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import VacancyMetroList from 'src/components/VacancySearchItem/MetroList';
import { useCardContainerClassNames } from 'src/components/VacancySearchItem/hooks/useCardContainerClassNames';
import translation from 'src/components/translation';
import useExperiment from 'src/hooks/useExperiment';
import { useIsHhru } from 'src/hooks/useSites';
import { UXFeedback } from 'src/utils/uxfeedback';

import { VacancyAddressProps } from 'src/components/VacancySearchItem/Address/types';
import { getAddressName } from 'src/components/VacancySearchItem/Address/utils';

const VacancyAddress: TranslatedComponent<VacancyAddressProps> = ({ area, address, trls }) => {
    const addressMetroStations = address?.metroStations?.metro ?? [];
    const areaMetroStations = addressMetroStations.filter((metro) => metro['@cityId'] === area?.['@id']);
    const { wideCardContainerClassName, narrowCardContainerClassName } = useCardContainerClassNames();
    const isHhRu = useIsHhru();
    const isAddressWithCity = Boolean(address?.city);
    const isAddressExp = useExperiment('vacancy_search_item_address_exp', isHhRu && isAddressWithCity, false);
    const metroList = isAddressExp ? addressMetroStations : areaMetroStations;
    const isAddressWithMetro = metroList.length > 0;
    const addressName = getAddressName({ area, address, isAddressWithMetro, isAddressExp, trls });

    const areaNameElement = addressName && (
        <Text typography="label-3-regular" Element="span" data-qa="vacancy-serp__vacancy-address">
            {addressName}
        </Text>
    );

    useEffect(() => {
        if (isAddressExp) {
            UXFeedback.sendEvent('portfolio_37914_search_snippet_address');
        }
    }, [isAddressExp]);

    return (
        <>
            <div className={narrowCardContainerClassName}>
                <VSpacing default={12} />
                {areaNameElement}
                {isAddressWithMetro && (
                    <>
                        <VSpacing default={4} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>

            <div className={wideCardContainerClassName}>
                <VSpacing default={8} />
                {areaNameElement}
                {isAddressWithMetro && (
                    <>
                        <HSpacing default={12} />
                        <VacancyMetroList metroList={metroList} />
                    </>
                )}
            </div>
        </>
    );
};

export default translation(VacancyAddress);
