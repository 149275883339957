import { useCallback } from 'react';

import vacancyResponseShowResumeButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_show_resume_button_click';
import { Cell, CellText, Link as MagritteLink, Radio as MagritteRadio, useBreakpoint } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { type TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Debug from 'HHC/Debug';
import translation from 'src/components/translation';
import { type ResponseStatusResume } from 'src/models/applicantVacancyResponseStatuses';
import { type ResumeVisibility } from 'src/models/vacancyResponsePopup';
import { type VacancySearchItem } from 'src/types/search/vacancy/card';

import ResumeAvatar from 'src/components/VacancyResponseForm/ResumeAvatar';
import ResumeDetail from 'src/components/VacancyResponseForm/ResumeDetail';
import ResumeHint from 'src/components/VacancyResponseForm/ResumeHint';

interface ResumeProps {
    resume: ResponseStatusResume;
    selected: boolean;
    single: boolean;
    hidden: boolean;
    visibility: ResumeVisibility;
    vacancy: VacancySearchItem;
    isQuestion?: boolean;
    isBottomSheet?: boolean;
}

const TrlKeys = {
    resumeLink: {
        view: 'vacancy.response.popup.resume.link',
        toSettings: 'vacancy.response.popup.resume.settings',
    },
    incompleteHint: 'vacancy.response.popup.unfinished.hint',
    emptyTitle: 'resume.title.empty',
};

const Resume: TranslatedComponent<ResumeProps> = ({
    resume,
    selected,
    single,
    hidden,
    visibility,
    vacancy,
    trls,
    isQuestion,
    isBottomSheet,
}) => {
    if (!resume.title?.[0]?.string) {
        Debug.log('out error', new Error('Empty resume title'), {
            resume: {
                title: resume.title,
                titleFirst: resume.title?.[0],
                titleString: resume.title?.[0]?.string,
                accessType: resume.accessType,
                isIncomplete: resume.isIncomplete,
                ...resume._attributes,
            },
        });
    }
    const { hash, isIncomplete } = resume;
    // тайтла может не быть, если это незавершенное дублированное резюме
    const name = resume.title?.[0]?.string ?? trls[TrlKeys.emptyTitle];

    const { isMobile } = useBreakpoint();

    const handleViewResumeClick = useCallback(() => {
        vacancyResponseShowResumeButtonClick({ vacancyId: `${vacancy?.vacancyId}` });
    }, [vacancy?.vacancyId]);

    const link = hidden ? (
        <MagritteLink
            Element={SPALink}
            to={`/applicant/resumes/edit/visibility?resume=${hash}`}
            target="_blank"
            typography={!isMobile ? 'label-2-regular' : 'label-3-regular'}
        >
            {trls[TrlKeys.resumeLink.toSettings]}
        </MagritteLink>
    ) : (
        <MagritteLink
            Element={SPALink}
            to={`/resume/${hash}`}
            target="_blank"
            data-qa="vacancy-response-view-resume"
            typography={!isMobile ? 'label-2-regular' : 'label-3-regular'}
            onClick={handleViewResumeClick}
        >
            {trls[TrlKeys.resumeLink.view]}
        </MagritteLink>
    );
    const renderRadio = () => (
        <MagritteRadio
            id={`resume_${hash}`}
            name="resumeId"
            value={resume.hash}
            checked={selected}
            disabled={hidden}
            readOnly
        />
    );

    const hasRightRadio = !single && isBottomSheet;
    const hasLeftRadio = !single && !isBottomSheet;
    const leftContent = hasLeftRadio ? renderRadio() : undefined;

    const renderRightContent = () => {
        if (hasRightRadio) {
            return renderRadio();
        }
        return !isMobile ? link : undefined;
    };

    return (
        <>
            <Cell left={leftContent} avatar={<ResumeAvatar resume={resume} />} right={renderRightContent()}>
                <CellText type="title" maxLines={2} data-qa="resume-title">
                    {name}
                </CellText>
                <CellText type="subtitle" maxLines={1} data-qa="resume-detail">
                    <ResumeDetail resume={resume} />
                </CellText>
            </Cell>
            {!isMobile && (
                <ResumeHint
                    resume={resume}
                    visibility={visibility}
                    vacancy={vacancy}
                    hidden={hidden}
                    incomplete={isIncomplete}
                    visible={selected}
                    isQuestion={isQuestion}
                />
            )}
        </>
    );
};

export default translation(Resume);
