import { useRef, useCallback, FC, ReactNode, MutableRefObject } from 'react';
import classnames from 'classnames';

import employerVacancyItemButtonClick from '@hh.ru/analytics-js-events/build/xhh/common/vacancy_search/employer_page_vacancy_search/employer_vacancy_item_button_click';
import { Card, VSpacing } from '@hh.ru/magritte-ui';

import { updateUrl } from 'Modules/url';
import BrandedSnippet from 'src/components/BrandedSnippet';
import BrandedSnippetProvider from 'src/components/BrandedSnippet/context/BrandedSnippetProvider';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import useVacancyOfTheDayAnalytics from 'src/components/VacanciesOfTheDay/hooks/useVacancyOfTheDayViewAnalytics';
import translation from 'src/components/translation';
import useSearchResultShown from 'src/hooks/useSearchResultShown';
import { useSelector } from 'src/hooks/useSelector';
import { SearchType } from 'src/types/search/common';
import { VacancySearchItem as VacancySearchItemType } from 'src/types/search/vacancy/card';
import { VacancyOfTheDayLocation } from 'src/utils/sendAdvSpyAnalytics';

import VacancySearchItemContent from 'src/components/VacancySearchItem/VacancySearchItemContent';
import Source from 'src/components/VacancySearchItem/types/Source';
import { getVacancyLinkURL } from 'src/components/VacancySearchItem/utils/getVacancyLinkURL';

const VacancyOfTheDaySpyingProps = {
    elementLockTime: 2000,
    entryPercent: 0.5,
};

interface VacancySearchItemProps {
    vacancy: VacancySearchItemType;
    vacancySource: Source;
    hhtmFromLabel?: string;
    criteriaText?: string;
    render?: (content: ReactNode, vacancyRef: MutableRefObject<HTMLDivElement | undefined>) => ReactNode;
}

const VacancySearchItem: FC<VacancySearchItemProps> = ({
    vacancy,
    vacancySource,
    hhtmFromLabel,
    criteriaText,
    render,
}) => {
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);

    const {
        '@isAdv': isAdv,
        metallic,
        vacancyId,
        searchRid,
        clickUrl,
        '@click': advClickUrl,
        links,
        isVacancyOfTheDay,
    } = vacancy;
    const vacancyRef = useRef<HTMLDivElement>();
    const searchLoading = useSelector((state) => state.searchLoading);

    const vacancyOfTheDayViewAnalyticsSubscribe = useVacancyOfTheDayAnalytics(
        vacancy,
        VacancyOfTheDayLocation.VacancySearchResult,
        VacancyOfTheDaySpyingProps
    );

    const searchResultShown = useSearchResultShown({ searchType: SearchType.Vacancy, itemId: vacancyId, searchRid });

    const content = (
        <VacancySearchItemContent
            innerRef={(element) => {
                vacancyOfTheDayViewAnalyticsSubscribe(element);
                vacancyRef.current = element ?? undefined;
            }}
            vacancy={vacancy}
            vacancySource={vacancySource}
            hhtmFromLabel={hhtmFromLabel}
            criteriaText={criteriaText}
        />
    );

    const url = getVacancyLinkURL({
        criteriaText: criteriaText ?? '',
        isAdv,
        clickUrl,
        advClickUrl,
        isVacancyOfTheDay,
        desktopLink: links.desktop,
    });

    const onCardClick = useCallback(() => {
        vacancySource === Source.EmployerVacancySearch &&
            employerVacancyItemButtonClick({ vacancyId, employerId: vacancy.company.id });
        window.open(
            updateUrl(url, {
                hhtmFromLabel,
                hhtmFrom: hhtmSource,
            }),
            '_blank'
        );
    }, [hhtmFromLabel, hhtmSource, url, vacancy.company.id, vacancyId, vacancySource]);

    const cardElement = (
        <Card.Skeleton
            loading={searchLoading}
            height={300}
            data-qa={classnames('vacancy-serp__vacancy', {
                [`vacancy-serp__vacancy_${metallic}`]: metallic,
                'vacancy-serp-item_clickme': isAdv,
            })}
            borderRadius={24}
            padding={12}
            borderWidth="default"
            increaseShadow
            stretched
            onClick={() => {
                const selection = window.getSelection();
                if (!selection?.toString().length) {
                    onCardClick();
                }
            }}
        >
            {render ? render(content, vacancyRef) : content}
        </Card.Skeleton>
    );

    return (
        <ElementShownAnchor key={`${vacancyId}${searchRid || ''}`} fn={searchResultShown}>
            <MagritteWrapper isEnabled>
                <BrandedSnippetProvider brandSnippet={vacancy.brandSnippet}>
                    <BrandedSnippet {...vacancy}>{cardElement}</BrandedSnippet>
                </BrandedSnippetProvider>
                <VSpacing default={16} />
            </MagritteWrapper>
        </ElementShownAnchor>
    );
};

export default translation(VacancySearchItem);
