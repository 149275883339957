import { FC } from 'react';

import { Text, Link } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';

import OrganizationForm from 'src/components/Employer/OrganizationForm';
import useGetCompanyUrl from 'src/hooks/companies/useGetCompanyUrl';
import { useSelector } from 'src/hooks/useSelector';
import { VacancyCompany } from 'src/types/search/vacancy/card';

const Features = {
    hideInterfaceForModerators: 'hide_interface_for_moderators',
};

interface CompanyNameProps {
    company: VacancyCompany;
    advClickUrl?: string;
    isEmployerLinkDisabled?: boolean;
}

const CompanyName: FC<CompanyNameProps> = ({ company, advClickUrl, isEmployerLinkDisabled }) => {
    const userId = useSelector(({ userId }) => userId);
    const hideInterfaceForModerators = useSelector(
        ({ features }) => features[Features.hideInterfaceForModerators]
    ) as string;
    const companyUrl = useGetCompanyUrl(company, advClickUrl);
    const shouldHideInterfaceForModerators = hideInterfaceForModerators?.split(',')?.includes(userId);
    const companyNameText = (
        <>
            <OrganizationForm organizationFormId={company.employerOrganizationFormId} />
            {company.visibleName}
        </>
    );

    if (!company.id) {
        return (
            <Text typography="label-3-regular" data-qa="vacancy-serp__vacancy-employer-text" Element="span">
                {company.visibleName}
            </Text>
        );
    }

    if (shouldHideInterfaceForModerators) {
        return (
            <Text typography="label-3-regular" data-qa="vacancy-serp__vacancy-employer-text" Element="span">
                {companyNameText}
            </Text>
        );
    }

    if (isEmployerLinkDisabled) {
        return (
            <Text typography="label-3-regular" data-qa="vacancy-serp__vacancy-employer-text" Element="span">
                {companyNameText}
            </Text>
        );
    }

    return (
        <Link
            typography="label-3-regular"
            Element={SPALink}
            to={companyUrl}
            style="neutral"
            data-qa="vacancy-serp__vacancy-employer"
            inline
        >
            {companyNameText}
        </Link>
    );
};

export default CompanyName;
