import { Tag, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { LabelType } from 'src/components/Label';
import translation from 'src/components/translation';
import { useSearchSettings } from 'src/hooks/search/useSearchSettings';
import { VacancySearchItem } from 'src/types/search/vacancy/card';

import { useVacancyLabels } from 'src/components/VacancySearchItem/Labels/useVacancyLabels';

import styles from './styles.less';

const TrlKeys = {
    skillsPercentage: 'label.skillsPercentage',
    [LabelType.NoResume]: 'label.noResume',
    [LabelType.BeFirst]: 'label.fewResponses',
};

interface LabelsProps {
    vacancy: VacancySearchItem;
}

const Labels: TranslatedComponent<LabelsProps> = ({ vacancy, trls }) => {
    const {
        isFewResponsesLabelShown,
        isNoResumeLabelShown,
        isKeySkillPercentLabelShown,
        matchedKeySkillAnalyticsData,
        isAnyLabelShown,
    } = useVacancyLabels({
        vacancy,
    });
    const { isVacancySearchMapPage } = useSearchSettings();

    if (!isAnyLabelShown) {
        return null;
    }

    return (
        <>
            <VSpacing default={24} gteS={isVacancySearchMapPage ? 24 : 16} />
            <div className={styles.labels}>
                {isKeySkillPercentLabelShown && (
                    <Tag style="positive" data-qa={`vacancy-label-${LabelType.SkillsPercentage}`}>
                        {formatToReactComponent(trls[TrlKeys.skillsPercentage], {
                            '{0}': matchedKeySkillAnalyticsData?.matchedKeySkillPercent ?? '',
                        })}
                    </Tag>
                )}
                {isNoResumeLabelShown && (
                    <Tag style="positive" data-qa={`vacancy-label-${LabelType.NoResume}`}>
                        {trls[TrlKeys[LabelType.NoResume]]}
                    </Tag>
                )}
                {isFewResponsesLabelShown && (
                    <Tag style="positive" data-qa={`vacancy-label-${LabelType.BeFirst}`}>
                        {trls[TrlKeys[LabelType.BeFirst]]}
                    </Tag>
                )}
            </div>
        </>
    );
};

export default translation(Labels);
