import { ElementShownAnchor } from '@hh.ru/analytics-js';
import vacancyKeySkillResumeMatchElementShown from '@hh.ru/analytics-js-events/build/xhh/applicant/vacancy_search/vacancy_key_skill_resume_match_element_shown';
import { ThumbUpOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import BlokoIconReplaceContainer from 'src/components/BlokoIconReplaceContainer';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

interface VacancyPercentIndicatorProps {
    vacancyId: number;
    trls: {
        [key: string]: string;
        skillsMatch: string;
    };
}

const TrlKeys = {
    skillsMatch: 'vacancy.view.skills.match',
};

export enum ScreenTypes {
    Suitable = 'SUITABLE',
    Simple = 'SIMPLE',
}

const VacancyPercentIndicator: TranslatedComponent<VacancyPercentIndicatorProps> = ({
    vacancyId,
    trls,
}: VacancyPercentIndicatorProps) => {
    const {
        usedResumeId: resumeId = 0,
        matchingResultMap,
        isSuitableSearch,
    } = useSelector(
        (state: {
            searchCounts: {
                isLoad: boolean;
                value: number;
                matchingResultMap?: { [key: number]: { match_percentage: number } };
                usedResumeId?: number;
                isSuitableSearch?: boolean;
            };
        }) => state.searchCounts || {}
    );

    const percent = matchingResultMap?.[vacancyId]?.match_percentage || 0;

    const analyticsData = {
        matchedKeySkillPercent: percent,
        vacancyId,
        resumeId,
        screenType: isSuitableSearch ? ScreenTypes.Suitable : ScreenTypes.Simple,
    };

    if (!percent) {
        return null;
    }

    return (
        <ElementShownAnchor fn={vacancyKeySkillResumeMatchElementShown} {...analyticsData}>
            <div className="vacancy-percent-indicator">
                <span className="vacancy-percent-number">{percent}%</span>
                <span className="vacancy-percent-text">{trls[TrlKeys.skillsMatch]}</span>
                <BlokoIconReplaceContainer>
                    <ThumbUpOutlinedSize16 initialColor="positive" />
                </BlokoIconReplaceContainer>
            </div>
        </ElementShownAnchor>
    );
};

export default translation(VacancyPercentIndicator);
