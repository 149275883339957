import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useGetCompanyUrl from 'src/hooks/companies/useGetCompanyUrl';
import { useIsZarplataPlatform } from 'src/hooks/usePlatform';
import { VacancySearchItem } from 'src/types/search/vacancy/card';

import BrandSnippetView from 'src/components/BrandedSnippet/components/BrandSnippetView';
import { useBrandedSnippetContext } from 'src/components/BrandedSnippet/context/BrandedSnippetContext';

const BrandedSnippet: TranslatedComponent<VacancySearchItem> = ({ children, trls, ...vacancy }) => {
    const { company, vacancyId, vacancyProperties } = vacancy;
    const companyUrl = useGetCompanyUrl(company);
    const brandingData = useBrandedSnippetContext();
    const isZpCrossposting = useIsZarplataPlatform() && !!vacancyProperties?.calculatedStates?.ZP?.crosspostedFrom;

    if (!brandingData) {
        return <>{children}</>;
    }

    return (
        <BrandSnippetView
            companyName={company.name}
            companyUrl={companyUrl}
            vacancyId={vacancyId}
            isZpCrossposting={isZpCrossposting}
            brandingData={brandingData}
        >
            {children}
        </BrandSnippetView>
    );
};

export default translation(BrandedSnippet);
