import { ImgHTMLAttributes } from 'react';

export type BrandSnippetTemplateList = {
    id: number;
    name: string;
}[];

export interface BrandGradient {
    colorList?: {
        color?: string;
        position?: number;
    }[];
    angle?: number;
}

export enum PictureType {
    Logo = 'logo',
    Picture = 'picture',
}

export enum PictureScalableType {
    LogoScalable = 'logoScalable',
    PictureScalable = 'pictureScalable',
}

export enum ScreenSize {
    Default = 'default',
    Mobile = 'xs',
}

export interface PictureScalableAttribute {
    width?: number;
    height?: number;
    url?: string;
}

export interface PictureScalableAttributeEdit extends PictureScalableAttribute {
    id?: number;
    originalFileName?: string;
}

interface PictureScalable {
    [ScreenSize.Default]?: PictureScalableAttribute;
    [ScreenSize.Mobile]?: PictureScalableAttribute;
}

interface PictureIds {
    [ScreenSize.Default]?: {
        id: number;
    };
    [ScreenSize.Mobile]?: {
        id: number;
    };
}

interface PictureScalableEdit {
    [ScreenSize.Default]?: PictureScalableAttributeEdit;
    [ScreenSize.Mobile]?: PictureScalableAttributeEdit;
}

interface BrandSnippetLegacy {
    logo?: string;
    logoXs?: string;
    picture?: string;
    pictureXs?: string;
}

export enum BrandSnippetBackgroundType {
    Color = 'color',
    Gradient = 'gradient',
}
export interface BrandSnippetBackground {
    background?: {
        [BrandSnippetBackgroundType.Color]?: string;
        [BrandSnippetBackgroundType.Gradient]?: BrandGradient;
    };
}

interface BrandSnippetBase extends BrandSnippetBackground, BrandSnippetLegacy {
    name?: string;
    id?: number;
}

export interface BrandSnippet extends BrandSnippetBase {
    [PictureScalableType.LogoScalable]?: PictureScalable;
    [PictureScalableType.PictureScalable]?: PictureScalable;
}

export interface BrandSnippetEdit extends BrandSnippetBase {
    [PictureScalableType.LogoScalable]?: PictureScalableEdit;
    [PictureScalableType.PictureScalable]?: PictureScalableEdit;
}

export interface BrandSnippetCreateDto extends BrandSnippetBackground {
    [PictureScalableType.LogoScalable]?: PictureIds;
    [PictureScalableType.PictureScalable]?: PictureIds;
}

export interface BrandSnippetEditDto extends BrandSnippetBackground {
    name: string;
    [PictureScalableType.LogoScalable]?: PictureIds;
    [PictureScalableType.PictureScalable]?: PictureIds;
}

export type PictureAttributes = ImgHTMLAttributes<HTMLImageElement>;

export interface BrandSnippetDecoration {
    hasLoadingError: boolean;
    isLoading: boolean;
    hasAdditionalPicture: boolean;
    logo?: PictureAttributes;
    picture?: PictureAttributes;
    showViewForXs: boolean;
    backgroundStyle: {
        backgroundImage?: string;
        backgroundColor?: string;
    };
}
