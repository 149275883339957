import { FC, useRef } from 'react';

import { employerAchievementCardTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/applicant/branding/employer_page/employer_achievement_card_tooltip_element_shown';
import { TooltipHover } from '@hh.ru/magritte-ui';
import { HrBrandOutlinedCircledSize16, HrBrandOutlinedSize24, HrBrandOutlinedSize16 } from '@hh.ru/magritte-ui/product';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import HoverTip, { TipPlacement, TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';

import BadgeContainer from 'src/components/Employer/BadgeContainer';
import { useSelector } from 'src/hooks/useSelector';
import { HrBrand } from 'src/models/employer/employerInfo';

import HrBrandContent from 'src/components/HrBrandIcon/HrBrandContent';

const IconSizeMap = {
    small: HrBrandOutlinedSize16,
    medium: HrBrandOutlinedSize24,
} as const;

export type HrBrandIconSize = keyof typeof IconSizeMap;

interface Props {
    hrBrand: HrBrand;
    size?: HrBrandIconSize;
    dataQa: string;
    isRedesignedIcon?: boolean;
    isVacancyPage?: boolean;
    isMagritte?: boolean;
    onClick?: VoidFunction;
}

const HrBrandIcon: FC<Props> = ({
    hrBrand,
    size = 'medium',
    dataQa,
    isRedesignedIcon,
    isVacancyPage,
    isMagritte,
    onClick,
}) => {
    const activatorRef = useRef<HTMLSpanElement>(null);
    const userType = useSelector((state) => state.userType);
    const employerId = useSelector((state) => state.employerInfo.id);
    if (!hrBrand.nominees && !hrBrand.winners) {
        return null;
    }
    const utmCampaign = isVacancyPage ? 'badge' : 'icon';
    const urlParams = `?utm_source=hh.ru&utm_medium=referral&utm_campaign=${utmCampaign}&utm_term=${userType}`;

    const dataQaSpecific = dataQa
        ? Object.keys(hrBrand)
              .map((hrBrandType) => {
                  return `${dataQa}_${hrBrandType}`;
              })
              .join(' ')
        : '';

    const url = `${hrBrand?.nominees?.['@url'] || hrBrand?.winners?.['@url'] || ''}${urlParams}`;

    if (isMagritte) {
        return (
            <SPALink
                to={url}
                target="_blank"
                rel="nofollow noindex"
                data-qa={dataQa ? `${dataQa} ${dataQaSpecific}` : ''}
            >
                <span ref={activatorRef}>
                    <HrBrandOutlinedCircledSize16 initialColor="warning-secondary" />
                </span>
                <TooltipHover placement="top-center" activatorRef={activatorRef}>
                    <HrBrandContent hrBrand={hrBrand} />
                </TooltipHover>
            </SPALink>
        );
    }

    const IconComponent = IconSizeMap[size];

    return (
        <SPALink
            to={url}
            target="_blank"
            rel="nofollow noindex"
            onClick={onClick}
            data-qa={dataQa ? `${dataQa} ${dataQaSpecific}` : ''}
        >
            <HoverTip
                render={() => <HrBrandContent hrBrand={hrBrand} />}
                onHover={(show) =>
                    show &&
                    employerAchievementCardTooltipElementShownRaw({
                        employerId: String(employerId),
                        achievementType: 'hr_brand',
                    })
                }
                placement={TipPlacement.Top}
                layer={TipLayer.Overlay}
                host={!process.env.SSR ? document.body : null}
                activatorRef={activatorRef}
            >
                {isRedesignedIcon ? (
                    <span className={'vacancy-serp-bage-hr-brand'} ref={activatorRef} />
                ) : (
                    <span ref={activatorRef}>
                        <BadgeContainer size={size} icon={<IconComponent initialColor="accent" />} />
                    </span>
                )}
            </HoverTip>
        </SPALink>
    );
};
export default HrBrandIcon;
