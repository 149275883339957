import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import vacancyResponseAlertPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_primary_button_click';
import vacancyResponseAlertScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_screen_shown';
import vacancyResponseAlertSecondaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_secondary_button_click';
import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button as MagritteButton,
    Link as MagritteLink,
    Modal as MagritteModal,
    Text,
    Title,
    useBreakpoint,
    ActionBar,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { useNotification } from 'src/components/Notifications/Provider';
import applicantResponseAbort from 'src/components/Notifications/VacancyResponseAbort';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const profileVisibilityAgreementAction = makeSetStoreField('countriesProfileVisibilityAgreement');

const ARTICLES = {
    uzbekistan: {
        conditions: 'https://rabota.by/article/31423',
        rules: 'https://rabota.by/article/31423#clause1',
    },
    common: {
        conditions: 'https://rabota.by/article/31422',
        rules: 'https://rabota.by/article/31422#clause1',
    },
};

const TrlKeys = {
    title: {
        common: 'otherCountriesProfileVisibilityAgreement.title.common',
        uzbekistan: 'otherCountriesProfileVisibilityAgreement.title.uzbekistan',
    },
    description: {
        common: 'otherCountriesProfileVisibilityAgreement.description.common',
        uzbekistan: 'otherCountriesProfileVisibilityAgreement.description.uzbekistan',
    },
    linkConditions: 'otherCountriesProfileVisibilityAgreement.link.conditions',
    linkRights: 'otherCountriesProfileVisibilityAgreement.link.rights',
    agree: 'otherCountriesProfileVisibilityAgreement.agree',
    cancel: 'otherCountriesProfileVisibilityAgreement.cancel',
};

interface Props {
    vacancyId: number;
}

const ALERT_NAME = 'vacancy_response_personal_data_permission';

const CountriesProfileVisibilityAgreementPopup: TranslatedComponent<Props> = ({ vacancyId, trls }) => {
    const dispatch = useDispatch();
    const countriesProfileVisibilityAgreement = useSelector((state) => state.countriesProfileVisibilityAgreement);
    const isVisible = Boolean(countriesProfileVisibilityAgreement?.show);
    const countriesProfileVisibilityAgreementVacancyId = countriesProfileVisibilityAgreement?.vacancyId ?? 0;
    const { addNotification } = useNotification();

    const { isMobile } = useBreakpoint();

    useEffect(() => {
        const isAlertVisible = isVisible && countriesProfileVisibilityAgreementVacancyId === vacancyId;
        if (isAlertVisible) {
            vacancyResponseAlertScreenShown({
                vacancyId: `${vacancyId}`,
                alertName: ALERT_NAME,
                hhtmSource: ALERT_NAME,
            });
        }
    }, [isVisible, countriesProfileVisibilityAgreementVacancyId, vacancyId]);

    if (countriesProfileVisibilityAgreement?.vacancyId !== vacancyId) {
        return null;
    }

    const dismiss = () => {
        addNotification(applicantResponseAbort);
        dispatch(
            profileVisibilityAgreementAction({
                ...countriesProfileVisibilityAgreement,
                confirmed: false,
                show: false,
            })
        );
    };

    const dismissByButton = () => {
        vacancyResponseAlertSecondaryButtonClick({
            buttonText: trls[TrlKeys.cancel],
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dismiss();
    };

    const confirm = () => {
        vacancyResponseAlertPrimaryButtonClick({
            buttonText: trls[TrlKeys.agree],
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dispatch(
            profileVisibilityAgreementAction({
                ...countriesProfileVisibilityAgreement,
                confirmed: true,
                show: false,
            })
        );
    };

    const isCommon = countriesProfileVisibilityAgreement.countryType === 'common';
    const titleTrl = isCommon ? TrlKeys.title.common : TrlKeys.title.uzbekistan;
    const descriptionTrl = isCommon ? TrlKeys.description.common : TrlKeys.description.uzbekistan;
    const articles = isCommon ? ARTICLES.common : ARTICLES.uzbekistan;

    const title = (
        <Title Element="h2" size="large" alignment={isMobile ? 'center' : 'left'}>
            {trls[titleTrl]}
        </Title>
    );

    const content = (
        <Text Element="p" style="secondary" typography="paragraph-1-regular">
            {formatToReactComponent(trls[descriptionTrl], {
                '{0}': (
                    <MagritteLink
                        Element={SPALink}
                        to={articles.conditions}
                        typography="paragraph-1-regular"
                        inline
                        target="_blank"
                    >
                        {trls[TrlKeys.linkConditions]}
                    </MagritteLink>
                ),
                '{1}': (
                    <MagritteLink
                        Element={SPALink}
                        to={articles.rules}
                        typography="paragraph-1-regular"
                        inline
                        target="_blank"
                    >
                        {trls[TrlKeys.linkRights]}
                    </MagritteLink>
                ),
            })}
        </Text>
    );

    if (isMobile) {
        return (
            <BottomSheet
                visible={isVisible}
                header={title}
                footer={
                    <BottomSheetFooter>
                        <MagritteButton
                            mode="primary"
                            style="accent"
                            data-qa="countries-profile-visibility-popup-confirm"
                            onClick={confirm}
                        >
                            {trls[TrlKeys.agree]}
                        </MagritteButton>
                        <MagritteButton
                            mode="secondary"
                            style="accent"
                            data-qa="countries-profile-visibility-popup-cancel"
                            onClick={dismissByButton}
                        >
                            {trls[TrlKeys.cancel]}
                        </MagritteButton>
                    </BottomSheetFooter>
                }
                onClose={dismiss}
            >
                {content}
            </BottomSheet>
        );
    }

    return (
        <MagritteModal
            visible={isVisible}
            title={title}
            actions={<Action mode="secondary" icon={CrossOutlinedSize24} onClick={dismiss} />}
            footer={
                <ActionBar
                    primaryActions={
                        <MagritteButton
                            mode="primary"
                            style="accent"
                            size="small"
                            data-qa="countries-profile-visibility-popup-confirm"
                            onClick={confirm}
                        >
                            {trls[TrlKeys.agree]}
                        </MagritteButton>
                    }
                />
            }
            onClose={dismiss}
        >
            {content}
        </MagritteModal>
    );
};

export default translation(CountriesProfileVisibilityAgreementPopup);
