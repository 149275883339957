import { FC } from 'react';

import Analytics from '@hh.ru/analytics-js';
import { applicantCallIntentExternal } from '@hh.ru/analytics-js-events/build/xhh/applicant/contacts/applicant_call_intent';
import { useBreakpoint } from '@hh.ru/magritte-ui';
import { ChatWritePossibility } from '@hh.ru/types-hh-microcore';

import VacancyContactsMagritte from 'src/components/VacancyView/VacancyContactsMagritte';
import VacancyContactsRedesignedV2 from 'src/components/VacancyView/VacancyContactsRedesignedV2';
import { VacancyChatInfoType } from 'src/models/vacancyChatInfo';
import { VacancyAddress, ContactInfo } from 'src/models/vacancyView.types';

interface VacancySearchItemContactsProps {
    callTrackingEnabled?: boolean;
    contactInfo?: ContactInfo;
    onClose: () => void;
    vacancyId: number;
    address?: VacancyAddress;
    chatButtonInfo: VacancyChatInfoType | null;
    employerId: number;
    chatWritePossibility: ChatWritePossibility;
    isQuestionExp?: boolean;
    isContactsRedesignExp?: boolean;
}

const VacancySearchItemContacts: FC<VacancySearchItemContactsProps> = ({
    contactInfo,
    vacancyId,
    callTrackingEnabled,
    chatButtonInfo,
    employerId,
    onClose,
    chatWritePossibility,
    isQuestionExp,
    isContactsRedesignExp,
}) => {
    const isVacancyChatEnable = chatWritePossibility !== 'DISABLED';
    const isChatEnabled = !!(chatButtonInfo && isVacancyChatEnable && employerId);

    const onClickPhone = (numberType?: string) => {
        const eventParams: {
            from: string;
            vacancy_id: number;
            numberType?: string;
        } = {
            from: 'serp',
            vacancy_id: vacancyId,
        };
        if (numberType) {
            eventParams.numberType = numberType;
        }
        Analytics.sendHHEvent('applicantCallIntent', eventParams);
        applicantCallIntentExternal();
    };

    const onClickEmail = () => {
        Analytics.sendHHEvent('applicantEmailIntent', {
            from: 'serp',
            vacancy_id: vacancyId,
        });
    };

    const { isMobile } = useBreakpoint();

    if (isContactsRedesignExp) {
        return (
            <VacancyContactsRedesignedV2
                contactInfo={contactInfo}
                onPhoneClick={onClickPhone}
                onEmailClick={onClickEmail}
                isNameHidden={isMobile}
                vacancyId={vacancyId}
                employerId={employerId}
                isChatEnabled={isChatEnabled}
            />
        );
    }

    return (
        <VacancyContactsMagritte
            vacancyId={vacancyId}
            contactInfo={contactInfo}
            onPhoneClick={onClickPhone}
            onEmailClick={onClickEmail}
            close={onClose}
            isCallTrackingEnabled={!!callTrackingEnabled}
            chatButtonInfo={chatButtonInfo}
            employerId={employerId}
            isChatEnabled={chatWritePossibility !== ChatWritePossibility.Disabled}
            isQuestionExp={isQuestionExp}
        />
    );
};

export default VacancySearchItemContacts;
