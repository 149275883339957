import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import vacancyResponseAlertPrimaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_primary_button_click';
import vacancyResponseAlertScreenShown from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_screen_shown';
import vacancyResponseAlertSecondaryButtonClick from '@hh.ru/analytics-js-events/build/xhh/applicant/response/vacancy_response_alert_secondary_button_click';
import { Alert, Button, Text } from '@hh.ru/magritte-ui';
import { ExclamationCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

const relocationWarningAction = makeSetStoreField('relocationWarning');

const TrlKeys = {
    title: 'vacancy.respond.popup.title',
    message: 'vacancy.respond.popup.message',
    country: 'vacancy.respond.popup.country',
    notice: 'vacancy.respond.popup.notice',
    confirm: 'vacancy.respond.popup.force',
    abort: 'vacancy.respond.popup.not',
    cancel: 'vacancy.respond.popup.cancel',
};

interface RelocationWarningPopupProps {
    vacancyId: number;
}

const ALERT_NAME = 'vacancy_response_foreign_employer';

const RelocationWarningPopup: TranslatedComponent<RelocationWarningPopupProps> = ({ vacancyId, trls }) => {
    const dispatch = useDispatch();
    const relocationWarning = useSelector((state) => state.relocationWarning);
    const relocationWarningVacancyId = relocationWarning?.vacancyId ?? 0;
    const relocationWarningShow = Boolean(relocationWarning?.show);

    useEffect(() => {
        const isAlertShown = relocationWarningShow && relocationWarningVacancyId === vacancyId;
        if (isAlertShown) {
            vacancyResponseAlertScreenShown({
                vacancyId: `${vacancyId}`,
                alertName: ALERT_NAME,
                hhtmSource: ALERT_NAME,
            });
        }
    }, [relocationWarningVacancyId, relocationWarningShow, vacancyId]);

    if (relocationWarningVacancyId !== vacancyId) {
        return null;
    }

    const confirm = () => {
        vacancyResponseAlertPrimaryButtonClick({
            buttonText: trls[TrlKeys.confirm],
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dispatch(relocationWarningAction({ ...relocationWarning, confirmed: true, show: false }));
    };

    const dismiss = () => dispatch(relocationWarningAction({ ...relocationWarning, confirmed: false, show: false }));

    const dismissByButton = () => {
        vacancyResponseAlertSecondaryButtonClick({
            buttonText: trls[TrlKeys.cancel],
            vacancyId: `${vacancyId}`,
            alertName: ALERT_NAME,
            hhtmSource: ALERT_NAME,
        });

        dismiss();
    };

    return (
        <Alert
            visible={relocationWarningShow}
            title={
                <Text typography="title-4-semibold" data-qa="relocation-warning-title">
                    {trls[TrlKeys.title]}
                </Text>
            }
            description={trls[TrlKeys.message]}
            icon={<ExclamationCircleFilledSize24 />}
            buttons={
                <>
                    <Button mode="primary" style="accent" data-qa="relocation-warning-confirm" onClick={confirm}>
                        {trls[TrlKeys.confirm]}
                    </Button>
                    <Button
                        mode="secondary"
                        style="accent"
                        data-qa="relocation-warning-abort"
                        onClick={dismissByButton}
                    >
                        {trls[TrlKeys.cancel]}
                    </Button>
                </>
            }
            layout="vertical"
            onClose={dismiss}
        />
    );
};

export default translation(RelocationWarningPopup);
